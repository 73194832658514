<template>
  <div>
    <h1
      class="border-0 font-semibold border-b border-solid border-gray-400 py-3 px-2 text-blackdok text-lg"
    >
      Changer Mon mot de passe
    </h1>

    <div class="px-5 py-5 flex flex-col gap-y-4">
      <ValidationObserver ref="observer" class="flex flex-col gap-y-3">
        <ValidationProvider
          :bails="false"
          name="Mot de passe actuel"
          rules="required"
          id="currect_password"
          class="w-full"
        >
          <template slot-scope="{ errors }">
            <dok-input
              size="lg"
              d-placeholder="Mot de passe actuel"
              :d-type="!showpassword.first ? 'password' : 'text'"
              :d-model.sync="actualPassword"
              label="Mot de passe actuel :"
              v-model="actualPassword"
              :custom-class="
                errors[0] || currentPasswordError
                  ? 'border border-red-600 border-solid'
                  : ''
              "
            >
              <template v-slot:rightIcon>
                <svg
                  class="cursor-pointer"
                  @click="showpassword.first = !showpassword.first"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                  />
                </svg>
              </template>
            </dok-input>
            <p v-if="errors[0]" class="text-red-700">
              Veuillez remplir ce champ
            </p>
          </template>
        </ValidationProvider>
        <ValidationProvider
          :bails="false"
          name="password"
          rules="required|verify_password|min:8"
          id="password"
          class="w-full"
        >
          <template slot-scope="{ errors }">
            <dok-input
              size="lg"
              d-placeholder="Nouveau mot de passe"
              :d-type="!showpassword.second ? 'password' : 'text'"
              :d-model.sync="newPassword"
              label="Nouveau mot de passe :"
              v-model="newPassword"
              :custom-class="
                errors[0] ? 'border border-red-600 border-solid' : ''
              "
            >
              <template v-slot:rightIcon>
                <svg
                  class="cursor-pointer"
                  @click="showpassword.second = !showpassword.second"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                  />
                </svg>
              </template>
            </dok-input>
            <div
              v-if="newPassword && newPassword.length"
              class="h-1 rounded-full my-2"
              :style="
                `width: ${percentPassword()}%; background: ${percentBackground(
                  percentPassword()
                )}`
              "
            ></div>
            <div class="flex flex-col" v-if="newPassword && newPassword.length">
              <span
                class="flex flex-row gap-x-1 items-center font-EffraR"
                :class="
                  validation.has_length ? 'text-green-500' : 'text-red-500'
                "
              >
                <i class="far fa-check-circle" v-if="validation.has_length"></i
                ><i
                  class="far fa-times-circle"
                  v-if="!validation.has_length"
                ></i
                >Veuillez saisir au moins 8 caractères.
              </span>
              <span
                class="flex flex-row gap-x-1 items-center font-EffraR"
                :class="
                  validation.has_number ? 'text-green-500' : 'text-red-500'
                "
              >
                <i class="far fa-check-circle" v-if="validation.has_number"></i
                ><i
                  class="far fa-times-circle"
                  v-if="!validation.has_number"
                ></i
                >Veuillez saisir quelques chiffres
              </span>
              <span
                class="flex flex-row gap-x-1 items-center font-EffraR"
                :class="
                  validation.has_lowercase ? 'text-green-500' : 'text-red-500'
                "
              >
                <i
                  class="far fa-check-circle"
                  v-if="validation.has_lowercase"
                ></i
                ><i
                  class="far fa-times-circle"
                  v-if="!validation.has_lowercase"
                ></i
                >Veuillez saisir des lettres minuscules
              </span>
              <span
                class="flex flex-row gap-x-1 items-center font-EffraR"
                :class="
                  validation.has_uppercase ? 'text-green-500' : 'text-red-500'
                "
              >
                <i
                  class="far fa-check-circle"
                  v-if="validation.has_uppercase"
                ></i
                ><i
                  class="far fa-times-circle"
                  v-if="!validation.has_uppercase"
                ></i
                >Veuillez saisir des lettres majuscules
              </span>
              <span
                class="flex flex-row gap-x-1 items-center font-EffraR"
                :class="
                  validation.has_special ? 'text-green-500' : 'text-red-500'
                "
              >
                <i class="far fa-check-circle" v-if="validation.has_special"></i
                ><i
                  class="far fa-times-circle"
                  v-if="!validation.has_special"
                ></i
                >Veuillez saisir quelques caractères spéciaux
              </span>
            </div>
            <p v-if="errors[0]" class="text-red-700">
              Veuillez remplir ce champ
            </p>
          </template>
        </ValidationProvider>
        <ValidationProvider
          :bails="false"
          name="Confirmer le mot de passe"
          rules="required|confirmed:password"
          id="confirm_password"
          class="w-full"
        >
          <template slot-scope="{ errors }">
            <dok-input
              size="lg"
              d-placeholder="Confirmer le mot de passe"
              :d-type="!showpassword.last ? 'password' : 'text'"
              :d-model.sync="confirmNewPassword"
              label="Confirmer le mot de passe :"
              v-model="confirmNewPassword"
              :custom-class="
                errors[0] ? 'border border-red-600 border-solid' : ''
              "
            >
              <template v-slot:rightIcon>
                <svg
                  class="cursor-pointer"
                  @click="showpassword.last = !showpassword.last"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                  />
                </svg>
              </template>
            </dok-input>
            <p v-if="errors[0]" class="text-red-700">
              Le mot de passe et la confirmation ne sont pas identiques
            </p>
          </template>
        </ValidationProvider>
      </ValidationObserver>
      <div class="flex justify-end">
        <dok-button
          type="submit"
          size="md"
          custom-class="uppercase"
          @click.native="savePassword"
          >Sauvegarder</dok-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      actualPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        has_length: false
      },
      showpassword: {
        first: false,
        second: false,
        last: false
      },
      currentPasswordError: false
    };
  },
  watch: {
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true
    },
    newPassword(newVal, oldVal) {
      this.validation.has_number = /\d/.test(this.newPassword);
      this.validation.has_lowercase = /[a-z]/.test(this.newPassword);
      this.validation.has_uppercase = /[A-Z]/.test(this.newPassword);
      this.validation.has_length = this.newPassword.length > 7;
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.newPassword
      );
    }
  },
  methods: {
    async savePassword() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      if (this.percentPassword() !== 100) {
        return;
      }

      this.$vs.loading();
      this.$store.dispatch("auth/UPDATE_PASSWORD", {
        data: {
          actualPass: this.actualPassword,
          newPass: this.newPassword,
          confPass: this.confirmNewPassword
        },
        onData: this.onData
      });
    },
    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 20;
      if (this.validation.has_lowercase) precent = precent + 20;
      if (this.validation.has_uppercase) precent = precent + 20;
      if (this.validation.has_special) precent = precent + 20;
      if (this.validation.has_length) precent = precent + 20;

      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 20) return "#EF4444";
      if (val === 40) return "#DB2777";
      if (val === 60) return "#D97706";
      if (val === 80) return "#fbbf24";
      if (val === 100) return "#10B981";
    },
    onData(data) {
      this.$vs.notify({
        time: 4000,
        text: data.message,
        color: data.ok ? "success" : "danger",
        iconPack: "feather",
        icon: data.ok ? "icon-check" : "icon-x"
      });

      if (!data.ok) {
        //console.log(data.message)
        if (data.message === "Le mot de passe actuel n'est pas correcte") {
          this.$vs.loading.close();
          return (this.currentPasswordError = true);
        }
      }

      if (data.ok) {
        this.actualPassword = ""; // Not Null empty String
        this.newPassword = ""; // Not Null empty String
        this.confirmNewPassword = ""; // Not Null empty String
        this.$refs.observer.reset();
      }

      this.$vs.loading.close();
    }
  }
};
</script>
